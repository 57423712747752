import { CheckboxContainer, useAsyncCallback, useTranslate } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { trpc, trpcReact } from '../../client'
import { GlobalLoadingState } from '../molecules/global-loading-state'

export const InvoicingEmailCheckbox = memo(function InvoicingEmailCheckbox() {
  const translate = useTranslate()
  const getMyNotificationsConfigurationApiCall =
    trpcReact.notificationsConfiguration.getMyNotificationsConfiguration.useQuery()

  const configuration = getMyNotificationsConfigurationApiCall.data

  const checked = configuration?.sendInvoiceEmail ?? false

  const handleChange = useAsyncCallback(
    async (value: boolean) => {
      if (!configuration) return
      await trpc.notificationsConfiguration.updateMyNotificationConfiguration.mutate({
        ...configuration,
        sendInvoiceEmail: value,
      })
      await getMyNotificationsConfigurationApiCall.refetch()
    },
    [configuration, getMyNotificationsConfigurationApiCall],
  )

  if (!getMyNotificationsConfigurationApiCall.data) {
    return <GlobalLoadingState />
  }

  return (
    <CheckboxContainer size="small" checked={checked} onChange={handleChange}>
      {translate('receive_invoices_by_email')}
    </CheckboxContainer>
  )
})
